import '../styles/global.scss';
import '../styles/pages/cancel.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import {HeadFC} from "gatsby";
import {
    CrownImage,
    LoadingGif,
    LogoImage,
} from "../images";
import {useState} from "react";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import {toast, ToastContainer} from "react-toastify";
import {navigate} from 'gatsby';

const PaymentPage = () => {

    // const [eventStatus, setEventStatus] = useState(false);
    // useEffect( () => {
    //     if(!eventStatus){
    //         fbq("track", "Lead");
    //         console.log('fb track lead');
    //         setEventStatus(true);
    //     }
    // }, [eventStatus]);

    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUserName] = useState('');
    const [card, setCard] = useState('');
    const [reason, setReason] = useState('');
    const [isError, setIsError] = useState('');

    // Node version
    // const setForm = async (e: React.FormEvent<HTMLInputElement>) => {
    //     e.preventDefault();

    //     const response = await window
    //     .fetch('/api/form', {
    //         method: 'POST',
    //         headers: {
    //             "content-type": "application/json",
    //         },
    //         body: JSON.stringify({ 
    //             username: username, 
    //             email: email, 
    //             card: card, 
    //             reason: reason 
    //         }),
    //     })
    //     .then(res => res.json());

    //     setIsError(response);
    //     if(response !== 'Success'){
    //         setTimeout(() => {
    //             setIsError('');
    //         }, 2000);
    //     } else{
    //         navigate('/cancel-successful');
    //     }
    // }

    const validateForm = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        let errorCounter = 0;

        (email.length !== 0 && email.includes('@')) ? errorCounter : errorCounter += 1;
        (username.length !== 0) ? errorCounter : errorCounter += 1;
        (card.length === 4) ? errorCounter : errorCounter += 1;
        (reason.length !== 0) ? errorCounter : errorCounter += 1;

        if(errorCounter === 0){
            const messageArray = {
                'Email': email,
                'Username': username,
                'Card': card,
                'Reason': reason,
            };
            let message = '';
            Object.keys(messageArray).map(key => {
                message += '<b>' + key + ':</b> ' + messageArray[key] + '%0A';
            });

            const token = "917750430:AAE077UFH-9-4qj8Y3tdug4TRaWvJIFeBL4";
            const chatId = "-1001681538921";
            const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${message}`;
            sendForm(url);
        } else{
            setIsError('Please fill in all fields');

            setTimeout(() => {
                setIsError('');
            }, 3000);
        }
    }

    const sendForm = (fetchUrl) => {
        
        fetch(fetchUrl)
        .then(res => res.json())
        .then(
            (result) => {
                setEmail('');
                setUserName('');
                setCard('');
                setReason('');

                navigate('/cancel-successful');
            },
            (error) => {
                console.log('Error');
            }
        );
    }

    return (
        <div>
            <div className={`emailError ${(isError.length > 1) ? 'active' : ''}`}>{isError}</div>
            <form className="paymentForm" onSubmit={(e) => validateForm(e)}>
                <div className="paymentWrap">
                    <div className="paymentContainer">
                        <div className="paymentContainerTop">
                            <div className="paymentLogo">
                                <img src={LogoImage} width={150} alt=""/>
                            </div>
                            <>
                                <div className="emailNote">
                                    Fill out the form to cancel your subscription
                                </div>
                                <input value={username} id="username" onChange={(e) => setUserName(e.target.value)} type="text" placeholder="Name"
                                    className="formControl"/>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="E-Mail"
                                    className="formControl"/>
                                <p className="emailRemark">* Please do not encrypt your mail with your Apple ID so that we can find your account in the system</p>
                                <input 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={card} onChange={(e) => setCard(e.target.value)} type="text" placeholder="Last 4 numbers card"
                                    className="formControl"/>
                                <input value={reason} onChange={(e) => setReason(e.target.value)} type="text" placeholder="Reason for cancelation"
                                    className="formControl"/>
                            </>
                        </div>

                        <div className="paymentContainerPressedBottom">
                            <button disabled={isLoading} type="submit" className="payBtn">
                                <img src={CrownImage} alt=""/>
                                <span>Cancel subscription</span>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}
            <ToastContainer/>
        </div>
    )
}

export default PaymentPage;

export const Head: HeadFC = () => (
    <>
        <title>Cancel subscription - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

